import { useEffect, useState } from 'react';
import './App.css';
import Cookies from 'universal-cookie';
import { ref, onValue, child, get, update } from "firebase/database";
import database from './firebaseConfig.js';

function App() {

  const [statusAln, setStatusAln] = useState('');
  const [statusRbn, setStatusRbn] = useState('');

  const cookies = new Cookies();

  const checkLogin = () => {
    console.log("Check if logged!");
    if(cookies.get('user') == 'alina.bacanu' || cookies.get('user') == 'ruben.bacanu') {
      console.log("logged");
      listenStatusAln();
      listenStatusRbn();
    } else {
      let pass = prompt('Password!');
      if(pass.toLowerCase() == 'alina.bacanu' || pass.toLowerCase() == 'ruben.bacanu') {
        cookies.set('user', pass.toLocaleLowerCase(), { path: '/' });
        console.log("logged");
        listenStatusAln();
        listenStatusRbn();
      } else {
        window.location.replace('https://www.google.com');  
        console.log("not logged");
      }
    }
  }

  const listenStatusAln = () => {
    const statusRef = ref(database, 'aln');

    onValue(statusRef, (snapshot) => {
      const data = snapshot.val();
      setStatusAln(data);
      changeBorderColor(data, 'aln');
    });
  }

  const listenStatusRbn = () => {
    const statusRef = ref(database, 'rbn');

    onValue(statusRef, (snapshot) => {
      const data = snapshot.val();
      setStatusRbn(data);
      changeBorderColor(data, 'rbn');
    });
  }


  const setNewStatusAln = (newStatus) => {
    update(ref(database), {'aln': newStatus}).then(() => {
      setStatusAln(newStatus);
    });
  }

  const setNewStatusRbn = (newStatus) => {
    update(ref(database), {'rbn': newStatus}).then(() => {
      setStatusAln(newStatus);
    });
  }

  const changeBorderColor = (status, person) => {
    switch (status) {
      case 0:
        document.getElementById(person).style.borderColor = "green";
        break;

      case 1:
        document.getElementById(person).style.borderColor = "orange";
        break;

      case 2:
        document.getElementById(person).style.borderColor = "red";
        break;
    
      default:
        document.getElementById(person).style.borderColor = "yellow";
        break;
    }
  }

  const convertStatusToText = (status) => {
    switch (status) {
      case 0:
        return "Not in a meeting."
        
      case 1:
        return "In meeting!"

      case 2:
        return "In meeting with camera!"
    
      default:
        break;
    }
  }

  useEffect(() => {
    checkLogin();
  });

  return (
    <div className='App'>
      <div id='aln'>
        <div className='titleDiv'>ALN</div>
        { convertStatusToText(statusAln) }
        { (cookies.get('user') == 'alina.bacanu') ? 
          <div className='buttonsDiv'>
            <button onClick={() => setNewStatusAln(0)}>OFF</button>
            <button onClick={() => setNewStatusAln(1)}>Call</button>
            <button onClick={() => setNewStatusAln(2)}>Camera</button>
          </div>
         : null }
      </div>
      <div id='rbn'>
        <div className='titleDiv'>RBN</div>
        { convertStatusToText(statusRbn) }
        { (cookies.get('user') == 'ruben.bacanu') ? 
          <div className='buttonsDiv'>
            <button onClick={() => setNewStatusRbn(0)}>OFF</button>
            <button onClick={() => setNewStatusRbn(1)}>Call</button>
            <button onClick={() => setNewStatusRbn(2)}>Camera</button>
          </div>
         : null }
      </div>
    </div>
  );
}

export default App;
